import { Methods } from "internal/clients/Methods";
import { IWebchatClient } from "./IWebchatClient";
import { DataResponse } from "internal/clients";
import { ChatMessage, ChatResponse, Environment } from "internal/models";

export class WebchatClient implements IWebchatClient {
  methods: Methods;
  serviceUrl: string;

  constructor(serviceUrl: string, methods: Methods) {
    this.methods = methods;
    this.serviceUrl = serviceUrl;
  }

  async sendMessage(tenantCode: string, botCode: string, environment: Environment, chatMessage: ChatMessage): Promise<DataResponse<ChatResponse>> {
    const response = await this.methods.post(`${this.serviceUrl}/tenant/${tenantCode}/bot/${botCode}/env/${environment}/webchat/`, chatMessage);
    // localhost testing - you also have to update BotService to allow not getting a UserId
    //const response = await this.methods.post(`https://localhost:3979/tenant/${tenantCode}/bot/${botCode}/env/${environment}/webchat/`, chatMessage);
    const success = await this.methods.handleErrors(`Sending Chat Message`, response);
    if (!success) {
      return {
        success: false
      };
    }

    const json = await response.json();

    return {
      success: true,
      data: json as ChatResponse
    };
  }
}
