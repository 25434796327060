import { Icon, IconType, VisualCategory } from '@smartaction/styles';
import {
  DroppableData,
  ModalContainerIds,
  PortalConfirm,
  usePortalModal,
  useSidePanel,
  useWindows,
} from '@smartaction/visuals';
import { Module, Step, StepType } from 'internal/models';
import React, { useEffect } from 'react';
import { ActionMenu } from 'ui/controls';
import { useBots, useFlow } from 'contexts';
import { StepManager } from './StepManager';
import { UpdateNodeQuery } from '../UpdateNodeQuery';

type StepOwnerType = {
  id: string;
  name: string;
  steps: Step[];
};

type StepWindowProps<T extends StepOwnerType> = {
  typeName: string;
  owner: T;
  moduleId: string;
  editorView: () => React.ReactElement;
  deleteFunc: () => Promise<void>;
  moduleExpression: (m: Module) => T[];
  reorderSteps: (stepIds: string[]) => Promise<boolean>;
  deleteSteps: (steps: Step[]) => Promise<boolean>;
  addSteps: (
    steps: Step[],
    fromSource?: DroppableData,
    index?: number,
    isPaste?: boolean,
    targetId?: string,
  ) => Promise<boolean>;
  allowedTypes: StepType[];
  isContainer: boolean;
};

export const StepWindow = <T extends StepOwnerType>(props: StepWindowProps<T>) => {
  const {
    typeName,
    owner,
    moduleId,
    editorView,
    deleteFunc,
    reorderSteps,
    deleteSteps,
    addSteps,
    allowedTypes,
    isContainer,
  } = props;
  const confirm = usePortalModal(ModalContainerIds.Confirm);
  const windows = useWindows();
  const sidePanel = useSidePanel();
  const { updateFlow } = useFlow();
  const { isReadOnlyBot } = useBots();

  useEffect(() => {
    UpdateNodeQuery(moduleId, owner.id);
  }, []);

  const items = [
    {
      label: (
        <React.Fragment>
          <Icon type={IconType.Edit} /> Edit
        </React.Fragment>
      ),
      isDisabled: isReadOnlyBot,
      action: () => {
        sidePanel.setContents(`Edit ${owner.name}`, editorView());
        sidePanel.open();
      },
    },
    {
      isDisabled: isReadOnlyBot,
      label: (
        <React.Fragment>
          <Icon type={IconType.Delete} /> Delete
        </React.Fragment>
      ),
      action: () => {
        confirm.openModal(
          <PortalConfirm
            header="Are you sure?"
            content={`All steps in this ${typeName} will also be deleted.`}
            confirmButton={{
              label: (
                <React.Fragment>
                  <Icon type={IconType.Delete} /> Delete
                </React.Fragment>
              ),
              type: VisualCategory.Danger,
              clicked: async () => {
                await deleteFunc();
                windows.removeWindow(owner.id);
              },
            }}
            cancelButton={{ label: 'Cancel', type: VisualCategory.Light, clicked: () => {} }}
          />,
        );
      },
    },
  ];

  return (
    <div onFocusCapture={() => UpdateNodeQuery(moduleId, owner.id)}>
      <ActionMenu items={items} />
      <StepManager
        identifier={owner.id}
        steps={owner.steps}
        sourceIdentifier={{ type: typeName, parentId: moduleId }}
        stepsUpdated={(steps) => {
          updateFlow(() => (owner.steps = steps));
        }}
        reorderSteps={reorderSteps}
        addSteps={addSteps}
        deleteSteps={deleteSteps}
        allowedTypes={allowedTypes}
        hasTrash={true}
        isContainer={isContainer}
      />
      {confirm.modal}
    </div>
  );
};
