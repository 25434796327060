import { arrayRemove } from '@smartaction/common';
import { Color, Icon, IconType, VisualCategory } from '@smartaction/styles';
import {
  ModalContainerIds,
  PortalConfirm,
  usePortalContextMenu,
  usePortalModal,
  useSidePanel,
  useWindows,
} from '@smartaction/visuals';
import { useClient, useFlow, useModule, useSnapshot, useSelectedNodeIds } from 'contexts';
import { AllStepTypes, Container, EntryPoint, Module, PolicyType, Step, UnknownPolicy } from 'internal/models';
import React, { useEffect, useRef } from 'react';
import { Position, Handle, type Node, NodeProps } from '@xyflow/react';
import { EntryPointEditor } from '../editors';
import { StepWindow } from '../stepManagement';
import { Circle } from './shapes/Circle';
import { usePoliciesModal } from 'ui/components';
import { GetQueryParams, UpdateQuery } from 'ui/utils';
import { DesignViews } from '../../../DesignViews';
import { Searchable } from '../../Searchable';
import { useManipulateStep } from 'ui/hooks';

type EntryPointNodePropsType = Node<{ moduleId: string; entryPoint: EntryPoint }, 'entryPointNode'>;

export const EntryPointNode = ({ data, isConnectable }: NodeProps<EntryPointNodePropsType>) => {
  const windows = useWindows();
  const snapshot = useSnapshot();
  const sidePanel = useSidePanel();
  const client = useClient('flow');
  const confirm = usePortalModal(ModalContainerIds.Confirm);
  const { updateFlow } = useFlow();
  const module = useModule(data.moduleId);
  const entryPoint = data.entryPoint;
  const policyIcon = usePoliciesModal(
    entryPoint.id,
    [PolicyType.BargeIn, PolicyType.Retry, PolicyType.DTMFOnly],
    async (policyType: PolicyType) => {
      const result = await client.policies.createPolicyForEntryPointAsync(
        snapshot.snapshot.id,
        data.moduleId,
        entryPoint.id,
        policyType,
      );
      return result.data ?? new UnknownPolicy('', entryPoint.id, policyType);
    },
  );
  const typeName = 'EntryPoint';
  const ref = useRef<HTMLDivElement>(null);
  const { selectedNodeIds, setSelectedNodeIds } = useSelectedNodeIds();
  const { triggerStepRequests } = useManipulateStep();
  const params = GetQueryParams();
  const node = params.get('node');

  useEffect(() => {
    if (entryPoint.id === node) {
      setTimeout(() => {
        ref.current?.click();
        setSelectedNodeIds([entryPoint.id]);
      }, 1000);
    }
  }, [node]);

  const contextMenu = usePortalContextMenu(ref, [
    {
      label: (
        <span className={Color(VisualCategory.Primary)}>
          <Icon type={IconType.Stack} /> Open EntryPoint Window
        </span>
      ),
      action: () => openEntryPointWindow(),
      isDisabled: false,
    },
    {
      label: (
        <span className={Color(VisualCategory.Danger)}>
          <Icon type={IconType.Delete} /> Delete EntryPoint
        </span>
      ),
      action: () => {
        confirm.openModal(
          //TODO use Resource.isInUse to check if the resource is used before prompting delete (not wired up currently)
          <PortalConfirm
            header="Delete Node- Entry Point"
            content={
              <React.Fragment>
                <div>Are you sure you want to delete this entry point?</div>
              </React.Fragment>
            }
            confirmButton={{
              label: (
                <React.Fragment>
                  <Icon type={IconType.Delete} /> Delete
                </React.Fragment>
              ),
              type: VisualCategory.Danger,
              clicked: async () => {
                client.modules.entryPoints.deleteAsync(snapshot.snapshot.id, data.moduleId, entryPoint.id).then(() => {
                  updateFlow((x) => {
                    let module = x.modules.find((y) => y.id === data.moduleId);

                    let copyEntryPoints = [...module?.entryPoints!];
                    let filteredEntryPoints = copyEntryPoints.filter((x) => x.id !== entryPoint.id);
                    module!.entryPoints = filteredEntryPoints;
                  });
                });
              },
            }}
            cancelButton={{ label: 'Cancel', type: VisualCategory.Light, clicked: () => {} }}
          />,
        );
      },
      isDisabled: false,
    },
  ]);
  const handlePasteStep = async (step: Step, newStepId: string, targetId?: string) => {
    const copiedStep = Object.assign({}, { ...step, id: newStepId }) as Step;
    updateFlow(() => entryPoint.steps.splice(entryPoint.steps.findIndex((s) => s.id === targetId) + 1, 0, copiedStep));
    await triggerStepRequests(copiedStep, step.id);
  };

  // probably got deleted
  if (!entryPoint) return null;

  const openEntryPointWindow = () => {
    windows.addWindow({
      id: entryPoint.id,
      className: 'moduleItem',
      title: `${module.name}: ${entryPoint.name}`,
      children: (
        <StepWindow
          moduleId={data.moduleId}
          typeName={typeName}
          editorView={() => <EntryPointEditor moduleId={data.moduleId} entryPoint={entryPoint} />}
          deleteFunc={async () => {
            await client.modules.entryPoints.deleteAsync(snapshot.snapshot.id, data.moduleId, entryPoint.id);
            updateFlow(() => {
              const index = module.entryPoints.findIndex((ep) => ep.id === entryPoint.id);
              module.entryPoints = arrayRemove(module.entryPoints, index);
            });
            windows.removeWindow(entryPoint.id);
          }}
          owner={entryPoint}
          moduleExpression={(m: Module) => m.entryPoints}
          deleteSteps={async (steps) => {
            try {
              for (let step of steps) {
                await client.steps.deleteAsync(
                  snapshot.snapshot.id,
                  step.id,
                  new Container(typeName, data.moduleId, entryPoint.id),
                );

                updateFlow(async () => {
                  const index = entryPoint.steps.findIndex((s) => s.id === step.id);
                  if (index > -1) {
                    entryPoint.steps = arrayRemove(entryPoint.steps, index);
                  }
                });
              }
              return true;
            } catch (e) {
              return false;
            }
          }}
          reorderSteps={async (reorderedIds) => {
            try {
              await client.modules.entryPoints.reorderSteps(
                snapshot.snapshot.id,
                data.moduleId,
                entryPoint.id,
                reorderedIds,
              );
              return true;
            } catch {
              return false;
            }
          }}
          addSteps={async (steps, fromSource, index, isPaste, targetId) => {
            try {
              for (let i = steps.length - 1; i >= 0; i--) {
                const step = steps[i];
                if (!step.id || isPaste) {
                  const response = await client.modules.entryPoints.createStep(
                    snapshot.snapshot.id,
                    data.moduleId,
                    entryPoint.id,
                    step.type,
                    step.name,
                    isPaste ? entryPoint.steps.findIndex((s) => s.id === targetId) + 1 : index,
                  );
                  if (!response.success) {
                    return false;
                  } else {
                    if (isPaste && response.data) {
                      await handlePasteStep(step, response.data, targetId);
                    } else {
                      step.id = response.data!;
                    }
                  }
                } else {
                  await client.modules.entryPoints.addExistingStep(
                    snapshot.snapshot.id,
                    data.moduleId,
                    entryPoint.id,
                    step.id,
                    new Container(
                      fromSource?.sourceIdentifier?.type,
                      fromSource?.sourceIdentifier?.parentId,
                      fromSource?.identifier,
                    ),
                    index,
                  );
                }
              }
              return true;
            } catch (e) {
              console.error(e);
              return false;
            }
          }}
          allowedTypes={AllStepTypes}
          isContainer={true}
        />
      ),
      closeAction: () => {
        UpdateQuery(`view=${DesignViews.Module}-${module.id}`);
      },
    });
  };

  const clickHandler = (evt?: React.MouseEvent<HTMLDivElement>) => {
    if (evt?.detail === 1) {
      if (evt?.shiftKey) {
        if (selectedNodeIds.includes(entryPoint.id)) {
          setSelectedNodeIds(selectedNodeIds.filter((id) => id !== entryPoint.id));
        } else {
          setSelectedNodeIds([...selectedNodeIds, entryPoint.id]);
        }
      } else {
        setSelectedNodeIds([entryPoint.id]);
      }
    } else {
      openEntryPointWindow();
    }
  };

  const isSelected = selectedNodeIds.includes(entryPoint.id);

  return (
    <Searchable id={entryPoint.id}>
      <div className="entry-point-node">
        <span
          className="block-edit"
          onClick={() => {
            sidePanel.setContents(
              `Edit ${entryPoint.name}`,
              <EntryPointEditor entryPoint={entryPoint} moduleId={data.moduleId} />,
            );
            sidePanel.open();
          }}
        >
          <Icon type={IconType.Edit} />
        </span>
        <div ref={ref} className="entry-point node" onClick={clickHandler}>
          <Circle text={entryPoint.name} size={1} policyIcon={policyIcon.clickableIcon} isSelected={isSelected} />
        </div>
        <Handle
          type="source"
          position={Position.Bottom}
          style={{ background: '#555', marginBottom: '10px' }}
          isConnectable={isConnectable}
        />
        {contextMenu}
        {policyIcon.modal}
        {confirm.modal}
      </div>
    </Searchable>
  );
};
