import { IconType, VisualCategory } from '@smartaction/styles';
import { Script } from 'internal/models';
import React, { useEffect, useState } from 'react';
import { StepStyling, StepView, TypedStepProps } from '../../Step';
import {
  Button,
  CloseTabEvent,
  ListViewerOpenItemEvent,
  ListViewerPort,
  PortalConfirm,
  usePortalModal,
} from '@smartaction/visuals';
import { DefaultPublisher } from '@smartaction/common';
import { useClient, useFlow, useSnapshot, useBots } from 'contexts';
import { DataStepsColors } from '../../StepColorGroups';
import { EditorInterface, MonacoEditor } from 'ui/components';

export const ScriptStyling: StepStyling = {
  typeName: 'Script',
  icon: IconType.Code,
  ...DataStepsColors,
};

export type ScriptDetailsProp = {
  step: Script;
  showEditor?: boolean;
};

export const ScriptStepView: React.FC<TypedStepProps<Script>> = ({ step, manipulateStep }) => {
  return (
    <StepView
      step={step}
      styling={ScriptStyling}
      isCollapsible={true}
      manipulateStep={manipulateStep}
      isCollapsed={false}
    >
      <ScriptDetails step={step} />
    </StepView>
  );
};

export const ScriptDetails: React.FC<ScriptDetailsProp> = ({ step, showEditor = true }) => {
  const setModal = () => {
    DefaultPublisher.publish(
      new ListViewerOpenItemEvent('flowList', ListViewerPort.Left, {
        label: 'Script Step',
        id: step.id,
        renderFunc: () => <ScriptEditor step={step} />,
        dropType: 'module',
        closeable: 'RaiseEvent',
      }),
    );
  };

  return (
    <React.Fragment>
      {showEditor && <ScriptEditor step={step} />}
      <Button className={'mt-3'} type={VisualCategory.Primary} action={setModal}>
        {showEditor ? 'Open in Tab' : 'Edit'}
      </Button>
    </React.Fragment>
  );
};

type ScriptStepEditorProps = {
  step: Script;
};

const ScriptEditor: React.FC<ScriptStepEditorProps> = ({ step }) => {
  const { snapshot } = useSnapshot();
  const { updateFlow } = useFlow();
  const confirm = usePortalModal();
  const [script, setScript] = useState(step.script);
  const client = useClient('flow').steps.scripts;
  const validateClient = useClient('validator');

  // TODO: prop drill from parent
  const { isReadOnlyBot } = useBots();
  const isReadOnly = isReadOnlyBot;

  useEffect(() => {
    const subId = DefaultPublisher.subscribe('TabCloseRequestedEvent', (evt) => {
      if (evt.id === step.id && step.script === script) {
        DefaultPublisher.publish(new CloseTabEvent(step.id, true));
      } else if (evt.id === step.id && step.script !== script) {
        confirm.openModal(
          <PortalConfirm
            header="Close Script Editor?"
            content={`You have unsaved changes for the script for ${step.name}. Are you sure you want to close?`}
            confirmButton={{
              label: 'Close Anyway',
              type: VisualCategory.Danger,
              clicked: () => DefaultPublisher.publish(new CloseTabEvent(step.id, true)),
            }}
            cancelButton={{
              label: 'Cancel',
              type: VisualCategory.Light,
              clicked: () => {},
            }}
          />,
        );
      }
    });

    return () => DefaultPublisher.unsubscribe('TabCloseRequestedEvent', subId);
  }, [step, script]);

  useEffect(() => {
    setScript(step.script);
  }, [step.script]);

  const saveScript = async (value: string) => {
    const response = await client.setScript(snapshot.id, step.id, value);

    if (response) {
      updateFlow(() => {
        step.script = value;
      });
      setScript(value);
    }
  };

  return (
    <React.Fragment>
      <h3>Edit Script for {step.name}</h3>
      <MonacoEditor
        id={step.id}
        isReadOnly={isReadOnly}
        value={script}
        types={[EditorInterface.ConversationState]}
        onSave={saveScript}
        validateMethod={(tenant, snapshot, script) => validateClient.validateScriptStep(tenant, snapshot, script)}
      />
      {confirm.modal}
    </React.Fragment>
  );
};
