import { Color, Icon, IconType, VisualCategory } from '@smartaction/styles';
import { useContextMenu, useSidePanel } from '@smartaction/visuals';
import { useClient, useFlow, useModule, useSnapshot, useSelectedNodeIds } from 'contexts';
import React, { useEffect, useRef, useState } from 'react';
import { Handle, Position, type Node, NodeProps } from '@xyflow/react';
import { DecisionBranchEditor } from '../editors';
import { Decision, DecisionBranch } from 'internal/models';
import { splitLongString } from 'ui/utils';

export type BranchNodePropsType = Node<{ moduleId: string; decision: Decision; branch: DecisionBranch }, 'branchNode'>;

export const BranchNode = ({ data, isConnectable }: NodeProps<BranchNodePropsType>) => {
  const { updateFlow } = useFlow();
  const module = useModule(data.moduleId);
  const sidePanel = useSidePanel();
  const decision = data.decision;
  const branch = data.branch;
  const ref = useRef<HTMLDivElement>(null);
  const snapshot = useSnapshot();
  const client = useClient('flow');
  const [isContextMenuClicked, setIsContextMenuClicked] = useState(false);
  const { selectedNodeIds, setSelectedNodeIds } = useSelectedNodeIds();

  const [line1, setFirstLine] = useState<string>('');
  const [line2, setSecondLine] = useState<string>('');

  useEffect(() => {
    if (!decision || !branch) return;

    let lines = splitLongString(branch.name, 30);
    setFirstLine(lines[0]);
    setSecondLine(lines[1]);
  }, [branch.name]);

  const clickHandler = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (event.type === 'contextmenu') {
      setIsContextMenuClicked(true);
      return;
    }

    if (event.detail === 1) {
      if (event.shiftKey) {
        if (selectedNodeIds.includes(branch.id)) {
          setSelectedNodeIds(selectedNodeIds.filter((id) => id !== branch.id));
        } else {
          setSelectedNodeIds([...selectedNodeIds, branch.id]);
        }
      } else {
        setSelectedNodeIds([branch.id]);
      }
    } else {
      handleOnDoubleClick();
    }

    setIsContextMenuClicked(false);
  };

  const contextMenu = useContextMenu(
    ref,
    [
      {
        label: (
          <span className={Color(VisualCategory.Danger)}>
            <Icon type={IconType.Delete} /> Delete Branch
          </span>
        ),
        action: () => {
          client.modules.decisions
            .deleteBranchAsync(snapshot.snapshot.id, data.moduleId, decision.id, branch.id)
            .then(() => {
              updateFlow(() => {
                let copyBranches = [...decision.branches];

                decision!.branches = copyBranches.filter((x) => x.id !== branch.id);
              });
            });
        },
        isDisabled: false,
      },
    ],
    true,
  );

  if (!decision || !branch) return null;

  const handleOnDoubleClick = () => {
    sidePanel.setContents(
      'Edit Branch',
      <DecisionBranchEditor moduleId={module.id} decisionId={decision.id} branch={branch} />,
    );
    sidePanel.open();
  };

  let additionalLine =
    line2.length > 0 ? (
      <>
        <p className="branchNode__secondLine">{line2}</p>
      </>
    ) : (
      <></>
    );

  const isSelected = selectedNodeIds.includes(branch.id);

  return (
    <React.Fragment>
      <Handle type="target" position={Position.Top} style={{ background: '#555' }} isConnectable={false} />
      <div
        ref={ref}
        className="branchNode node"
        onClick={clickHandler}
        onContextMenu={clickHandler}
        onDoubleClick={handleOnDoubleClick}
        style={{
          border: isSelected ? '3.5px solid #222' : '2px solid #222',
          boxShadow: isSelected ? '0 4px 8px rgba(0, 0, 0, 0.4)' : 'none',
        }}
      >
        <div className="branchNode__general">
          <p className="branchNode__firstLine">{line1}</p>
          {additionalLine}
        </div>
        {isContextMenuClicked && contextMenu}
      </div>
      <Handle type="source" position={Position.Bottom} style={{ background: '#555' }} isConnectable={isConnectable} />
    </React.Fragment>
  );
};
