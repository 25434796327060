import { Color, ColorHexes, Icon, IconStyle, IconType, VisualCategory } from '@smartaction/styles';
import { useBots, useClient, useFlow, useSnapshot } from 'contexts';
import { Ask, PolicyType, Step, StepType, UnknownPolicy } from 'internal/models';
import React, { useRef, useState } from 'react';
import { EditableLabel } from 'ui/controls';
import { Tooltip } from 'react-tooltip';
import {
  Button,
  Field,
  useId,
  PortalModal,
  usePortalModal,
  useFromModal,
  usePortalContextMenu,
  PortalConfirm,
  ModalContainerIds,
} from '@smartaction/visuals';
import { POLICY_ICON_STATUS_COLOR } from 'ui/constants';
import { usePoliciesModal } from 'ui/components';
import { StepColors } from './StepColorGroups';
import { CLUTestModal } from './input/CLUTestModal';
import { Searchable } from '../../../Searchable';
import { v4 as uuid } from 'uuid';
import { PostSuccess } from 'ui/events';

export enum StepActionsEnum {
  Delete = 'Delete',
  Paste = 'Paste',
}
export type TypedStepProps<TStep extends Step> = {
  step: TStep;
  isContainer?: boolean;
  manipulateStep: (step: Step) => Promise<boolean>;
};

export type StepStyling = StepColors & {
  typeName: string;
  icon: IconType;
};

export type BaseStepProps = {
  step: Step;
  styling: StepStyling;
  isCollapsible?: boolean;
  branches?: React.ReactElement;
  manipulateStep: (step: Step, action?: string, targetId?: string) => Promise<boolean>;
  isCollapsed?: boolean;
};

export type StepProps = BaseStepProps & {
  children?: React.ReactNode;
};

const activeIconColor = POLICY_ICON_STATUS_COLOR.active;
const inactiveIconColor = POLICY_ICON_STATUS_COLOR.inactive;

export const sessionStorageCopiedItemKey = 'copiedStep';

export const StepView: React.FC<StepProps> = ({
  step,
  styling,
  children,
  isCollapsible,
  branches,
  manipulateStep,
  isCollapsed = true,
}) => {
  const { snapshot } = useSnapshot();
  const [collapsed, setCollapsed] = useState(isCollapsed);

  const client = useClient('flow');
  const flow = useFlow();
  const modal = usePortalModal();
  const [descriptionTooltipId] = useState(`description-tooltip-${uuid()}`);
  const { isReadOnlyBot } = useBots();
  const confirm = usePortalModal(ModalContainerIds.Confirm);
  let allowedPolicies = [PolicyType.Conditional];

  if (step.type === StepType.DTMFMenu || step.type === StepType.NumericInput || step.type === StepType.YesNo) {
    allowedPolicies = [
      PolicyType.BargeIn,
      PolicyType.Conditional,
      PolicyType.Validate,
      PolicyType.Timeout,
      PolicyType.DTMFOnly,
      PolicyType.InputFormatter,
    ];
  } else if (
    step.type === StepType.CLU ||
    step.type === StepType.TextEntry ||
    step.type === StepType.AddressInput ||
    step.type === StepType.ReceiveMedia
  ) {
    allowedPolicies = [PolicyType.BargeIn, PolicyType.Conditional, PolicyType.Validate, PolicyType.Timeout];
  } else if (step.type === StepType.WaitForAgent) {
    allowedPolicies = [PolicyType.Validate, PolicyType.Timeout];
  }

  if (step.type === StepType.CLU || step.type === StepType.TextEntry) {
    allowedPolicies.push(PolicyType.InputFormatter);
  }

  const policyIcon = usePoliciesModal(step.id, allowedPolicies, async (policyType: PolicyType) => {
    const result = await client.policies.createPolicyForStepAsync(snapshot.id, step.id, policyType);
    return result.data ?? new UnknownPolicy('', step.id, policyType);
  });
  const ref = useRef<HTMLDivElement>(null);

  const saveName = async (name: string) => {
    const result = await client.steps.updateName(snapshot.id, step.id, name);
    if (result.success) {
      flow.updateFlow(() => {
        step.name = name;
      });
    }
    return result.success;
  };

  const branchesView = branches ? <div className="branches">{branches}</div> : <React.Fragment />;

  let smallDetailsView = <React.Fragment />;
  let bigDetailsView = <React.Fragment />;

  if (children) {
    if (isCollapsible) {
      // noselect tells the Stack not to select the item just for clicking in the details view.
      bigDetailsView = (
        <div className="details noselect" style={{ backgroundColor: styling.detailsBackground ?? '#eee' }}>
          {children}
        </div>
      );
    } else {
      if (styling.detailsBackground) {
        // eslint-disable-next-line no-console
        console.warn(
          `Step type ${step.type} isn't collapsible but it specifies a details BG color. You're not gonna see anything.`,
        );
      }

      smallDetailsView = <div className="details-mini">{children}</div>;
    }
  }

  const descriptionIcon = step.description ? (
    <Icon type={IconType.Sticky} color={activeIconColor} size="xs" />
  ) : (
    <Icon type={IconType.Sticky} style={IconStyle.Outline} color={inactiveIconColor} size="xs" />
  );

  const descriptionView = (
    <React.Fragment>
      <span
        style={{ pointerEvents: isReadOnlyBot ? 'none' : 'initial', cursor: 'pointer' }}
        data-tooltip-id={descriptionTooltipId}
        data-tooltip-content={step.description}
        onClick={() =>
          modal.openModal(
            <EditValueModal
              label="Description"
              icon={IconType.Sticky}
              value={step.description}
              update={async (desc: string) => {
                await client.steps.updateDescription(snapshot.id, step.id, desc);
                flow.updateFlow(() => (step.description = desc));
              }}
            />,
          )
        }
      >
        {descriptionIcon}
      </span>
      <Tooltip
        id={descriptionTooltipId}
        positionStrategy="fixed"
        style={{ zIndex: 1000, width: '150px', wordBreak: 'break-word', display: 'block' }}
      />
    </React.Fragment>
  );

  const tagIcon =
    step.tags && step.tags.length ? (
      <Icon type={IconType.Tags} color={ColorHexes.Primary} size="lg" />
    ) : (
      <Icon type={IconType.Tags} color={inactiveIconColor} size="lg" style={IconStyle.Outline} />
    );

  const updateTags = async (tags: string) => {
    const splitTags = tags
      .split(';')
      .map((s) => s.trim())
      .filter((t) => Boolean(t));
    if (splitTags.join(' ') !== step.tags.join(' ')) {
      await client.steps.updateTags(snapshot.id, step.id, splitTags);
      flow.updateFlow(() => (step.tags = splitTags));
    }
  };

  const tagView = (
    <div
      className="tags icon-holder"
      onClick={() =>
        modal.openModal(
          <EditValueModal label="Tags" icon={IconType.Tags} value={step.tags.join(';')} update={updateTags} />,
        )
      }
    >
      {tagIcon}
    </div>
  );

  const contentsClasses = children && isCollapsible ? 'contents collapsible-details' : 'contents';

  const stepClass = children && (!isCollapsible || !collapsed) ? 'step open' : 'step';

  const contextMenuButtons = [
    {
      label: (
        <span className={Color(VisualCategory.Danger)}>
          <Icon type={IconType.Delete} /> Delete Step
        </span>
      ),
      action: async () => {
        confirm.openModal(
          <PortalConfirm
            header="Delete Step"
            content={
              <React.Fragment>
                <div>Are you sure you want to delete this Step?</div>
              </React.Fragment>
            }
            confirmButton={{
              label: (
                <React.Fragment>
                  <Icon type={IconType.Delete} /> Delete
                </React.Fragment>
              ),
              type: VisualCategory.Danger,
              clicked: async () => manipulateStep(step, StepActionsEnum.Delete),
            }}
            cancelButton={{ label: 'Cancel', type: VisualCategory.Light, clicked: () => {} }}
          />,
        );
      },
      isDisabled: isReadOnlyBot,
    },
    {
      label: (
        <span className={Color(VisualCategory.Primary)}>
          <Icon type={IconType.Copy} /> Copy Step
        </span>
      ),
      action: async () => {
        sessionStorage.setItem(sessionStorageCopiedItemKey, JSON.stringify(step));
        PostSuccess('Step Copied');
      },
      isDisabled: isReadOnlyBot,
    },

    {
      label: (
        <span className={Color(VisualCategory.Primary)}>
          <Icon type={IconType.Paste} /> Paste Step
        </span>
      ),
      action: async () => {
        const copiedStep = sessionStorage.getItem(sessionStorageCopiedItemKey);
        copiedStep &&
          (await manipulateStep(JSON.parse(copiedStep), StepActionsEnum.Paste, step.id).then(() => {
            PostSuccess('Step Pasted');
            return sessionStorage.removeItem(sessionStorageCopiedItemKey);
          }));
      },
      isDisabled: isReadOnlyBot || !sessionStorage.getItem(sessionStorageCopiedItemKey),
    },
  ];

  const contextMenu = usePortalContextMenu(ref, contextMenuButtons);

  return (
    <Searchable id={step.id}>
      <div className="step-container">
        <div ref={ref} className={stepClass} tabIndex={0} style={{ borderColor: styling.color }}>
          <div className="mainView">
            <div className={contentsClasses}>
              <div className="grip icon-holder" style={{ color: styling.color }}>
                <Icon type={IconType.Grip} />
              </div>
              <div className="icon icon-holder" style={{ color: styling.color }}>
                <Icon type={styling.icon} size="lg" />
              </div>
              <div className="labels">
                <label className="stepName" style={{ color: styling.color }}>
                  {styling.typeName}
                </label>
                <div className="name-and-description">
                  {isReadOnlyBot ? (
                    <label className="name">{step.name}</label>
                  ) : (
                    <EditableLabel text={step.name} className="name" inputLabel="Name" save={saveName} />
                  )}
                  {descriptionView}
                </div>
              </div>
              <CLUTestModal step={step as Ask} iconColor={activeIconColor} />
              {tagView}
              <div className="icon-holder">{policyIcon.clickableIcon}</div>
              <div className="collapse-button icon-holder" onClick={() => setCollapsed(!collapsed)}>
                <Icon type={collapsed ? IconType.Down : IconType.Up} color={styling.color} />
              </div>
            </div>
            {smallDetailsView}
          </div>
          {bigDetailsView}
        </div>
        {branchesView}
      </div>
      {contextMenu}
      {modal.modal}
      {policyIcon.modal}
      {confirm.modal}
    </Searchable>
  );
  // minor note for above: collapseButton is always rendered the same, but unless step-container has collapsible-details, it'll be hidden, just so it takes up consistent
  // space and makes steps without collapsible render adjacent icons in the same place as collapsible ones.
};

type StepModalProps = {
  label: string;
  icon: IconType;
  value: string;
  update: (value: string) => Promise<void>;
};

const EditValueModal: React.FC<StepModalProps> = ({ label, icon, value, update }) => {
  const fieldId = useId('stepValue');
  const [newValue, setNewValue] = useState(value);
  const [isSaving, setIsSaving] = useState(false);
  const modal = useFromModal();
  const { isReadOnlyBot } = useBots();

  const header = {
    type: VisualCategory.Primary,
    content: (
      <React.Fragment>
        <Icon type={icon} /> Edit {label}
      </React.Fragment>
    ),
  };

  const content = (
    <React.Fragment>
      <Field inputId={fieldId} label={label} name="description" groupClass="col">
        <input
          id={fieldId}
          className="form-control"
          type="text"
          value={newValue}
          onChange={(evt) => setNewValue(evt.target.value)}
        />
      </Field>
    </React.Fragment>
  );

  const cancel = () => modal.forceClose();
  const saveClick = async () => {
    setIsSaving(true);
    await update(newValue ?? '');
    modal.forceClose();
  };

  const buttons = (
    <React.Fragment>
      <Button action={saveClick} isDisabled={isSaving || isReadOnlyBot} type={VisualCategory.Primary}>
        {isSaving ? 'Saving...' : 'Save'}
      </Button>
      <Button action={cancel} type={VisualCategory.Secondary}>
        Cancel
      </Button>
    </React.Fragment>
  );

  return <PortalModal header={header} content={content} buttons={buttons} />;
};
