import { ICellEditorParams } from 'ag-grid-community';
import { Pointer, PointerType } from 'internal/models';
import { PointerControl } from 'ui/components';
import React, { forwardRef } from 'react';

export const PointerEditor = forwardRef((props: ICellEditorParams) => {
  const pointer = props.value as Pointer;
  const { colDef } = props;
  const allowedTypes = (colDef as any).allowedTypes as PointerType[];

  const save = (pointer?: Pointer) => {
    const colId = props.column.getColId();
    const nodeId = props.node.id;
    if (nodeId && colId && pointer) {
      props.api.getRowNode(nodeId)?.setDataValue(colId, pointer);
    }
  };
  const width = props.column.getActualWidth();

  return (
    <div className="pointer-editor" style={{ width }}>
      <div className="mt-2">
        <PointerControl types={allowedTypes} pointer={pointer} update={(p) => save(p)} />
      </div>
    </div>
  );
});
