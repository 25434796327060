import React, { useEffect, useState } from 'react';
import './Overrides.Visuals.css';
import '@smartaction/authentication/index.css';
import 'react-json-pretty/themes/monikai.css';
import { Auth0, AuthenticationProvider, AzureAD, useAuthentication, useToken } from '@smartaction/authentication';
import { ClientProvider } from './contexts';
import { Main, TopBarSelectStyles } from 'ui/layout';
import { ConfigProvider, TenantProvider, useTenants } from 'contexts';
import { AccessProvider } from 'contexts/AccessContext';
import { useNavigate } from 'react-router';
import { Container, TopBar } from '@smartaction/visuals';
import Select from 'react-select';
import { ClientId } from 'ClientId';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { Environment } from 'internal/models';
import { Link } from 'ui/controls';
import { CredentialResponse, GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';

interface AzureJWT extends JwtPayload {
  unique_name: string;
  name: string;
  groups: string[];
}

const environment =
  window.location.origin.includes('miked') ||
  window.location.origin.includes('dev') ||
  window.location.origin.includes('localhost')
    ? Environment.DEV
    : window.location.origin.includes('qa')
    ? Environment.QA
    : Environment.PROD;

const isGoogle = window.location.origin.includes('capacity'); // change this to localhost if you need to test google authentication
const isEntraId = !window.location.origin.includes('customer');

type AuthProviderProps = {
  children: React.ReactNode
}

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  if (isGoogle) {
    return (
      <GoogleOAuthProvider clientId="1029272151797-4hos1q5jke3doh7j0oi184d018baeg1v.apps.googleusercontent.com">
        <GoogleAuthProvider>{children}</GoogleAuthProvider>
      </GoogleOAuthProvider>
    );
  }
  return isEntraId ? (
    <AzureAD
      clientId={ClientId}
      redirectDelayInMilliseconds={1500}
      authority="https://login.microsoftonline.com/c8f75edf-97bd-4e30-a937-a5dd220b4573"
    >
      {children}
    </AzureAD>
  ) : (
    <Auth0
      clientId="J8ueG0ZuxxNzdP1AXMbFndcl3AEraZeQ"
      domain="dev-thbyye5g.us.auth0.com"
      audience="https://dev-thbyye5g.us.auth0.com/api/v2/"
    >
      {children}
    </Auth0>
  );
};

const GoogleAuthProviderWrapper: React.FC<AuthProviderProps> = ({ children }) => {
  return (
    <div style={{ height: '100vh' }} className="d-flex justify-content-center align-items-center flex-column">
      <h3 className={'mb-3'}>Hi! Please use your Capacity account to continue.</h3>
      {children}
    </div>
  );
};
const GoogleAuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [googleToken, setGoogleToken] = useState<CredentialResponse>();
  if (googleToken) {
    return (
      <AuthenticationProvider refreshAndProvideToken={async () => googleToken.credential} logout={() => {}}>
        {children}
      </AuthenticationProvider>
    );
  }
  return (
    <GoogleAuthProviderWrapper>
      <GoogleLogin
        onSuccess={(credentialResponse) => {
          setGoogleToken(credentialResponse);
        }}
      />
    </GoogleAuthProviderWrapper>
  );
};

function App() {
  return (
    <AuthProvider>
      <ConfigProvider>
        <ClientProvider>
          <AccessProvider>
            <TenantProvider>
              <ActualApp />
            </TenantProvider>
          </AccessProvider>
        </ClientProvider>
      </ConfigProvider>
    </AuthProvider>
  );
}

const ActualApp: React.FC = () => {
  const tenants = useTenants();
  const navigate = useNavigate();
  const tokenHook = useToken();
  const [username, setUsername] = useState<string>();

  let authContext = useAuthentication();
  const changeTenant = (code: string) => {
    tenants.setTenant(code);
    navigate(code ? `/${code}` : '/');
  };

  const tenantOptions = Array.from(tenants?.availableTenants?.values() ?? []).map(
    (tenant: { name: string; code: string }) => ({ label: tenant.name, value: tenant.code }),
  );
  const selectedTenant = tenants.selectedTenant
    ? { label: tenants.selectedTenant.name, value: tenants.selectedTenant.code }
    : { label: '-- Select --', value: '' };

  useEffect(() => {
    if (tenants.availableTenants?.size) {
      if (authContext.redirectURI != null) {
        const route = authContext.redirectURI;
        navigate(route, { replace: true });
      }
    }

    //Check for token and set usernameDisplay
    if (!username) {
      tokenHook.get().then((t) => {
        if (t) {
          let tokenValues = jwt_decode<AzureJWT>(t);
          setUsername(tokenValues.unique_name);
        }
      });
    }
  }, []);

  //Handling for single tenant users
  useEffect(() => {
    if (tenantOptions.length === 1) {
      changeTenant(tenantOptions[0].value);
    }
  }, [tenants.availableTenants]);

  const version = process.env.REACT_APP_VERSION ? process.env.REACT_APP_VERSION : '1.0.local-dev';

  const navLinks = tenants.selectedTenant ? (
    <React.Fragment>
      <Link path={`${tenants.selectedTenant.code}/tenant-management`}>Manage Tenant</Link>
      <Link path={`${tenants.selectedTenant.code}/agents`}>Agents</Link>
    </React.Fragment>
  ) : (
    <React.Fragment />
  );

  return (
    <Container>
      <div id="version">
        <b>VERSION: </b>
        {version}
      </div>
      <div id="usernameDisplay">
        <b>USER: </b>
        {username ? username : ''}
      </div>
      <TopBar logoUrl={window.location.origin}>
        <PossibleNonProdEnvironmentIndicator />
        <div className="row">
          <div className="col-auto">
            <Select
              components={
                tenantOptions.length === 1 ? { DropdownIndicator: () => null, IndicatorSeparator: () => null } : {}
              }
              isDisabled={tenantOptions.length === 1}
              value={selectedTenant}
              options={tenantOptions}
              onChange={(opt) => {
                if (opt !== null) {
                  changeTenant(opt.value);
                }
              }}
              styles={TopBarSelectStyles}
            />
          </div>
          <div className="col d-flex">{navLinks}</div>
        </div>
      </TopBar>
      <div className="row g-0">
        <Main />
      </div>
    </Container>
  );
};

const PossibleNonProdEnvironmentIndicator: React.FC = () => {
  if (environment === Environment.PROD) {
    return <></>;
  }
  if (environment === Environment.QA) {
    return <div id="non-prod-indicator">QA</div>;
  }

  return (
    <div id="non-prod-indicator" className="dev">
      DEV
    </div>
  );
};

export default App;
