export enum ValidationEntitiesTypeEnum {
  None = 'None',
  API = 'API',
  Block = 'Block',
  Branch = 'Branch',
  Channel = 'Channel',
  Condition = 'Condition',
  Config = 'Config',
  Context = 'Context',
  Decision = 'Decision',
  EntryPoint = 'EntryPoint',
  Function = 'Function',
  Module = 'Module',
  Pointer = 'Pointer',
  Policy = 'Policy',
  Properties = 'Properties',
  Resource = 'Resource',
  Script = 'Script',
  Snapshot = 'Snapshot',
  Step = 'Step',
  Tag = 'Tag',
}
export class ValidationResponse {
  type: ResultType;
  message: string;
  entitiesStack: {
    id: string;
    type: ValidationEntitiesTypeEnum;
  }[];

  constructor(
    type: ResultType,
    message: string,
    entitiesStack: {
      id: string;
      type: ValidationEntitiesTypeEnum;
    }[],
  ) {
    this.type = type;
    this.message = message;
    this.entitiesStack = entitiesStack;
  }

  static fromJson(json: any) {
    return new ValidationResponse(
      json.type,
      json.message,
      json.entitiesStack,
    );
  }
}

export enum ResultType {
  Warning = 'Warning',
  Error = 'Error',
}
