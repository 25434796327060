import { arrayRemove } from '@smartaction/common';
import { Color, Icon, IconType, VisualCategory } from '@smartaction/styles';
import {
  ModalContainerIds,
  PortalConfirm,
  usePortalContextMenu,
  usePortalModal,
  useSidePanel,
  useWindows,
} from '@smartaction/visuals';
import { useClient, useFlow, useModule, useSnapshot, useSelectedNodeIds, useBots } from 'contexts';
import {
  AllStepTypes,
  Block,
  Container,
  EmptyId,
  ForEachTypes,
  Module,
  PointerType,
  PolicyType,
  Step,
  UnknownPolicy,
} from 'internal/models';
import React, { useRef, useEffect } from 'react';
import { Handle, Position, type Node, NodeProps } from '@xyflow/react';
import { BlockEditor } from '../editors';
import { StepWindow } from '../stepManagement';
import { Rectangle } from './shapes/Rectangle';
import { usePoliciesModal } from 'ui/components';
import { GetQueryParams, UpdateQuery } from 'ui/utils';
import { DesignViews } from '../../../DesignViews';
import { Searchable } from '../../Searchable';
import { UpdateNodeQuery } from '../UpdateNodeQuery';
import { useManipulateStep } from 'ui/hooks';
import { PostSuccess } from 'ui/events';

type BlockNodePropsType = Node<{ moduleId: string; block: Block }, 'blockNode'>;

export const BlockNode = ({ data, isConnectable }: NodeProps<BlockNodePropsType>) => {
  const windows = useWindows();
  const { snapshot } = useSnapshot();
  const { isReadOnlyBot } = useBots();
  const sidePanel = useSidePanel();
  const { flow, updateFlow } = useFlow();
  const client = useClient('flow');
  const module = useModule(data.moduleId);
  const block = data.block;
  const typeName = 'Block';
  const ref = useRef<HTMLDivElement>(null);
  const confirm = usePortalModal(ModalContainerIds.Confirm);
  const { selectedNodeIds, setSelectedNodeIds } = useSelectedNodeIds();
  const policyIcon = usePoliciesModal(
    block.id,
    [PolicyType.BargeIn, PolicyType.Retry, PolicyType.DTMFOnly],
    async (policyType: PolicyType) => {
      const result = await client.policies.createPolicyForBlockAsync(snapshot.id, data.moduleId, block.id, policyType);
      return result.data ?? new UnknownPolicy('', block.id, policyType);
    },
  );
  const { triggerStepRequests } = useManipulateStep();
  const params = GetQueryParams();
  const node = params.get('node');

  useEffect(() => {
    windows.removeWindow(data.block.id);
  }, [data.block.listToIterate]); // The block window was removed because the allowedTypes for the step changed.

  useEffect(() => {
    if (block.id === node) {
      setTimeout(() => {
        ref.current?.click();
        setSelectedNodeIds([block.id]);
      }, 1000);
    }
  }, [node]);

  const isForEachBlock = block.listToIterate.type !== PointerType.Empty;

  const contextMenuButtons = [
    {
      label: (
        <span className={Color(VisualCategory.Primary)}>
          <Icon type={IconType.Stack} /> Open Block Window
        </span>
      ),
      action: () => openBlockWindow(),
      isDisabled: false,
    },
    {
      label: (
        <span className={Color(VisualCategory.Danger)}>
          <Icon type={IconType.Delete} /> Delete Block
        </span>
      ),
      action: async () => {
        confirm.openModal(
          //TODO use Resource.isInUse to check if the resource is used before prompting delete (not wired up currently)
          <PortalConfirm
            header="Delete Node- Block"
            content={
              <React.Fragment>
                <div>Are you sure you want to delete this Block?</div>
              </React.Fragment>
            }
            confirmButton={{
              label: (
                <React.Fragment>
                  <Icon type={IconType.Delete} /> Delete
                </React.Fragment>
              ),
              type: VisualCategory.Danger,
              clicked: async () => {
                const result = await client.modules.blocks.deleteAsync(snapshot.id, data.moduleId, block.id);
                if (result.success) {
                  updateFlow((x) => {
                    let module = x.modules.find((y) => y.id === data.moduleId);

                    let copyBlocks = [...module?.blocks!];
                    let filteredBlocks = copyBlocks.filter((x) => x.id !== block.id);
                    module!.blocks = filteredBlocks;

                    if (flow.exceptionHandlerId === block.id) {
                      flow.exceptionHandlerId = EmptyId;
                    }
                  });
                }
              },
            }}
            cancelButton={{ label: 'Cancel', type: VisualCategory.Light, clicked: () => {} }}
          />,
        );
      },
      isDisabled: isReadOnlyBot,
    },
  ];

  let exceptionHandlerNotice = <React.Fragment />;
  if (flow.exceptionHandlerId === block.id) {
    exceptionHandlerNotice = (
      <div className="exception-handler-notice">
        <Icon type={IconType.Exception} /> Exception Handler
      </div>
    );
  } else {
    contextMenuButtons.push({
      label: (
        <span>
          <Icon type={IconType.Exception} /> Set as Exception Handler
        </span>
      ),
      action: async () => {
        const result = await client.setExceptionHandlerAsync(snapshot.id, block.id);
        if (result.success) {
          updateFlow((x) => {
            flow.exceptionHandlerId = block.id;
          });
        }
      },
      isDisabled: isReadOnlyBot,
    });
  }

  const contextMenu = usePortalContextMenu(ref, contextMenuButtons);

  const handlePasteStep = async (step: Step, newStepId: string, targetId?: string) => {
    const copiedStep = Object.assign({}, { ...step, id: newStepId }) as Step;
    updateFlow(() => block.steps.splice(block.steps.findIndex((s) => s.id === targetId) + 1, 0, copiedStep));
    PostSuccess(`Pasting...`);
    await triggerStepRequests(copiedStep, step.id);
  };

  // it's probably been deleted, so it's about to go away.
  if (!block) return null;

  const openBlockWindow = () => {
    windows.addWindow({
      id: block.id,
      className: 'moduleItem moduleItem--block',
      title: `${module.name}: ${block.name} ${isForEachBlock ? '(For Each)' : ''}`,
      children: (
        <StepWindow
          moduleId={data.moduleId}
          typeName={typeName}
          editorView={() => <BlockEditor block={block} moduleId={data.moduleId} />}
          deleteFunc={async () => {
            await client.modules.blocks.deleteAsync(snapshot.id, data.moduleId, block.id);
            updateFlow(() => {
              const index = module.blocks.findIndex((b) => b.id === block.id);
              module.blocks = arrayRemove(module.blocks, index);
            });
          }}
          owner={block}
          moduleExpression={(m: Module) => m.blocks}
          deleteSteps={async (steps) => {
            try {
              for (let step of steps) {
                await client.steps.deleteAsync(snapshot.id, step.id, new Container(typeName, data.moduleId, block.id));

                updateFlow(async () => {
                  const index = block.steps.findIndex((s) => s.id === step.id);
                  if (index > -1) {
                    block.steps = arrayRemove(block.steps, index);
                  }
                });
              }
              return true;
            } catch (e) {
              return false;
            }
          }}
          reorderSteps={async (reorderedIds) => {
            try {
              await client.modules.blocks.reorderSteps(snapshot.id, data.moduleId, block.id, reorderedIds);
              return true;
            } catch {
              return false;
            }
          }}
          addSteps={async (steps, fromSource, index, isPaste, targetId) => {
            try {
              for (let i = steps.length - 1; i >= 0; i--) {
                const step = steps[i];
                if (!step.id || isPaste) {
                  const response = await client.modules.blocks.createStep(
                    snapshot.id,
                    data.moduleId,
                    block.id,
                    step.type,
                    step.name,
                    isPaste ? block.steps.findIndex((s) => s.id === targetId) + 1 : index,
                  );
                  if (!response.success) {
                    return false;
                  } else {
                    if (isPaste && response.data) {
                      await handlePasteStep(step, response.data, targetId);
                    } else {
                      step.id = response.data!;
                    }
                  }
                } else {
                  await client.modules.blocks.addExistingStep(
                    snapshot.id,
                    data.moduleId,
                    block.id,
                    step.id,
                    new Container(
                      fromSource?.sourceIdentifier?.type,
                      fromSource?.sourceIdentifier?.parentId,
                      fromSource?.identifier,
                    ),
                    index,
                  );
                }
              }
              return true;
            } catch (e) {
              return false;
            }
          }}
          allowedTypes={isForEachBlock ? AllStepTypes : ForEachTypes}
          isContainer={true}
        />
      ),
      closeAction: () => {
        UpdateQuery(`view=${DesignViews.Module}-${module.id}`);
      },
      clickAction: () => UpdateNodeQuery(module.id, block.id),
    });
  };

  const clickHandler = (evt?: React.MouseEvent<HTMLDivElement>) => {
    if (evt?.detail === 1) {
      if (evt?.shiftKey) {
        if (selectedNodeIds.includes(block.id)) {
          setSelectedNodeIds(selectedNodeIds.filter((id) => id !== block.id));
        } else {
          setSelectedNodeIds([...selectedNodeIds, block.id]);
        }
      } else {
        setSelectedNodeIds([block.id]);
      }
    } else {
      openBlockWindow();
    }
  };

  const editButton = isReadOnlyBot ? (
    <></>
  ) : (
    <span
      className="block-edit"
      onClick={() => {
        sidePanel.setContents(`Edit ${block.name}`, <BlockEditor block={block} moduleId={data.moduleId} />);
        sidePanel.open();
      }}
    >
      <Icon type={IconType.Edit} />
    </span>
  );

  const isSelected = selectedNodeIds.includes(block.id);

  return (
    <Searchable id={block.id}>
      <div className="block-node">
        <Handle type="target" position={Position.Top} style={{ background: '#555' }} isConnectable={isConnectable} />
        {editButton}
        <div
          ref={ref}
          className={`blockNode node ${isForEachBlock ? 'blockNode--forEach' : ''}`}
          onClick={clickHandler}
        >
          {isForEachBlock && (
            <div className="blockNode__forEach">
              <Icon type={IconType.ForEach} /> For Each
            </div>
          )}
          <Rectangle
            text={block.name}
            size={1}
            policyIcon={policyIcon.clickableIcon}
            hasNoBottomRadius={flow.exceptionHandlerId === block.id}
            isSelected={isSelected}
          />
          {exceptionHandlerNotice}
        </div>
        <Handle type="source" position={Position.Bottom} style={{ background: '#555' }} isConnectable={isConnectable} />
        {contextMenu}
        {policyIcon.modal}
        {confirm.modal}
      </div>
    </Searchable>
  );
};
