import React from 'react';
import { useFlow, useSnapshot } from 'contexts';
import { ModuleChildTypes, Script, Step, StepType, ValidationEntitiesTypeEnum } from 'internal/models';
import { ListViewerOpenDefinedItemEvent, ListViewerPort } from '@smartaction/visuals';
import { GenerateNewQuery } from '../../module/UpdateNodeQuery';
import { Table } from 'ui/components';
import { v4 as uuid } from 'uuid';
import { ScriptDetails } from '../../module/stepManagement';
import { useNavigate } from 'react-router-dom';
import { DefaultPublisher } from '@smartaction/common';
import { DesignViews } from '../../../DesignViews';
import { NavigateToEntityEvent } from 'event';
import { Color, VisualCategory } from '@smartaction/styles';

export const Scripts: React.FC = () => {
  const { flow } = useFlow();
  const { snapshot } = useSnapshot();
  const navigate = useNavigate();

  const navigateToScript = (moduleId: string, parentId: string, stepId: string, type: string) => {
    const newQuery = GenerateNewQuery(moduleId, parentId);
    navigate(newQuery);
    DefaultPublisher.publish(
      new ListViewerOpenDefinedItemEvent('flowList', ListViewerPort.Left, `${DesignViews.Module}-${moduleId}`),
    );
    setTimeout(() => {
      DefaultPublisher.publish(
        new NavigateToEntityEvent([
          {
            id: snapshot.id,
            type: ValidationEntitiesTypeEnum.Snapshot,
          },
          {
            id: moduleId,
            type: ValidationEntitiesTypeEnum.Module,
          },
          {
            id: parentId,
            type: type as ValidationEntitiesTypeEnum,
          },
          {
            id: stepId,
            type: ValidationEntitiesTypeEnum.Step,
          },
        ]),
      );
    }, 250);
  };

  const getScriptsListSteps = (
    steps: Step[],
    moduleId: string,
    moduleName: string,
    parentId: string,
    parentName: string,
    parentType: ModuleChildTypes,
  ) =>
    steps
      .filter((step) => step.type === StepType.Script)
      .map((step) => ({
        moduleId: moduleId,
        moduleName: moduleName,
        parentId: parentId,
        parentName: parentName,
        parentType: parentType,
        ...step,
      }));

  const scriptsTableData = flow.modules.flatMap((module) => [
    ...module.blocks.flatMap((block) =>
      getScriptsListSteps(block.steps, module.id, module.name, block.id, block.name, ModuleChildTypes.Block),
    ),
    ...module.entryPoints.flatMap((ep) =>
      getScriptsListSteps(ep.steps, module.id, module.name, ep.id, ep.name, ModuleChildTypes.EntryPoint),
    ),
  ]);

  return (
    <div className={'p-2'}>
      <h3>Scripts</h3>
      <Table
        id="scriptsTable"
        headersGroup={['Script Parent', 'Script', 'Edit Script', 'Script Module']}
        columnsSize={['md', 'md', 'md', 'sm', 'sm']}
        rows={scriptsTableData.map((script, index) => {
          const { id, name, moduleId, moduleName, parentId, parentName, parentType } = script;
          return {
            id: uuid() + index,
            cells: [
              {
                type: 'customNode',
                id: uuid(),
                customNode: (
                  <div className={'d-flex gap-2 flex-column align-items-start'}>
                    <span className={'badge bg-primary'}>{parentType}</span>
                    <span>{parentName}</span>
                  </div>
                ),
              },
              {
                type: 'customNode',
                id: uuid(),
                customNode: (
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={() => navigateToScript(moduleId, parentId, id, parentType)}
                    className={`me-2 cursor-underline-style ${Color(VisualCategory.Primary)}`}
                  >
                    <u>{name}</u>
                  </span>
                ),
              },
              {
                type: 'customNode',
                id: uuid(),
                customNode: (
                  <div style={{ transform: 'scale(0.8)' }}>
                    <ScriptDetails showEditor={false} step={script as unknown as Script} />
                  </div>
                ),
              },
              {
                type: 'customNode',
                id: uuid(),
                customNode: <span>{moduleName}</span>,
              },
            ],
          };
        })}
      />
    </div>
  );
};
