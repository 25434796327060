import { Icon, IconType, VisualCategory } from '@smartaction/styles';
import { Button } from '@smartaction/visuals';
import { useClient, useCurrentBot, useTenant } from 'contexts';
import { Environment } from 'internal/models';
import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { v4 as uuid } from 'uuid';

export const Chat: React.FC = () => {
  const [chatActive, setChatActive] = useState(false);

  const text = chatActive ? 'Close Chat' : 'Open Chat';
  return (
    <React.Fragment>
      <Button type={VisualCategory.Accent} action={() => setChatActive(!chatActive)} className="btn-sm mx-2">
        <Icon type={IconType.Chat} /> {text}
      </Button>
      {createPortal(<ActualChat isActive={chatActive} />, document.getElementById('chatContainer')!)}
    </React.Fragment>
  );
};

type ActualChatProps = {
  isActive: boolean;
};

type Message = {
  isBot: boolean;
  message: string;
  timestamp?: Date;
};

export const ActualChat: React.FC<ActualChatProps> = ({ isActive }) => {
  const tenant = useTenant();
  const bot = useCurrentBot();
  const client = useClient('webchat');
  const messageRef = useRef<HTMLDivElement>(null);
  const [environment, setEnvironment] = useState(Environment.DEV);
  const [conversationId, setConversationId] = useState('');
  const [conversationActive, setConversationActive] = useState(false);
  const [newMessage, setNewMessage] = useState('');
  const [showPatience, setShowPatience] = useState(false);
  const [messages, setMessages] = useState<Array<Message>>([
    { message: 'Hello', isBot: true, timestamp: new Date(2024, 8, 20, 10, 0, 0) },
    { message: 'Hello!', isBot: false, timestamp: new Date(2024, 8, 20, 10, 1, 0) },
  ]);
  const classes = isActive ? 'active' : '';
  const inputClasses = conversationActive ? 'input active' : 'input';

  useEffect(() => {
    messageRef.current && messageRef.current.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const startEnvironment = async (environment: Environment) => {
    if (conversationActive) {
      await endConversation(true);
    }
    setEnvironment(environment);
    setConversationActive(true);
    await sendMessage('', true);
  };

  const endConversation = async (sendEndMessage: boolean = false) => {
    setConversationActive(false);
    setConversationId('');
    if (sendEndMessage) {
      await sendMessage('CONTROL-ENDCONVERSATION');
    }
  };

  const sendMessage = async (message: string, clearOldMessages: boolean = false) => {
    setShowPatience(true);
    let convoId = conversationId;
    if (!convoId) {
      convoId = uuid();
      setConversationId(convoId);
    }

    const result = await client.sendMessage(tenant!.code, bot!.code, environment, {
      input: message,
      conversationId: convoId,
    });
    let newMessages = [];

    if (result.success) {
      // we send an empty message to start a convo, but we don't need to save that.
      if (message) {
        newMessages.push({ message: message, isBot: false, timestamp: new Date() });
      }
      if (result.data!.messages) {
        const botMessages = result.data!.messages.map((m) => {
          return { message: m, isBot: true, timestamp: new Date() };
        });
        newMessages.push(...botMessages);
      }

      if (clearOldMessages) {
        setMessages(newMessages);
      } else {
        setMessages([...messages, ...newMessages]);
      }

      if (result.data!.conversationEnded) {
        endConversation();
      } else {
        setTimeout(() => document.getElementById('webchat-input')?.focus(), 200);
      }
    }
    setShowPatience(false);
    setNewMessage('');
  };

  const convoManagement = conversationActive ? (
    <div className="environment-select">
      <label>Manage Conversation</label>
      <div className="btn-group">
        <button className="btn btn-sm btn-success" onClick={() => startEnvironment(environment)}>
          Restart
        </button>
        <button className="btn btn-sm btn-danger" onClick={() => endConversation(true)}>
          End
        </button>
      </div>
    </div>
  ) : (
    <div className="environment-select">
      <label className="mx-2">Start Bot</label>
      <div className="btn-group">
        <button className="btn btn-sm btn-dark" onClick={() => startEnvironment(Environment.DEV)}>
          Sandbox
        </button>
        <button className="btn btn-sm btn-dark" onClick={() => startEnvironment(Environment.QA)}>
          Testing
        </button>
        <button className="btn btn-sm btn-dark" onClick={() => startEnvironment(Environment.UAT)}>
          UAT
        </button>
        <button className="btn btn-sm btn-dark" onClick={() => startEnvironment(Environment.PROD)}>
          Live
        </button>
      </div>
    </div>
  );

  const textArea = showPatience ? (
    <div className="chat-patience">Sending...</div>
  ) : (
    <textarea
      id="webchat-input"
      disabled={!conversationActive}
      className="form-control"
      value={newMessage}
      onChange={(e) => setNewMessage(e.currentTarget.value)}
      onKeyDown={(e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
          e.preventDefault();
          e.stopPropagation();
          sendMessage(newMessage);
        }
      }}
    />
  );

  return (
    <div id="chat" className={classes}>
      <h4>Test Chat</h4>
      {convoManagement}
      <div className="messages">
        {messages.map((m) => (
          <div ref={messageRef}>
            <MessageView {...m} />
          </div>
        ))}
      </div>
      <div className={inputClasses}>
        <div className="row g-0">
          <div className="col-9">{textArea}</div>
          <div className="col-3">
            <Button
              isDisabled={!conversationActive || !newMessage}
              type={VisualCategory.Primary}
              action={() => sendMessage(newMessage)}
            >
              Send
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const MessageView: React.FC<Message> = ({ isBot, message, timestamp }) => {
  const containerClasses = isBot ? 'row justify-content-start g-0' : 'row justify-content-end mx-2';

  const messageClasses = isBot ? 'message bot col-9' : 'message user col-9';

  const timestampView = timestamp ? (
    <label className="timestamp">{timestamp.toLocaleTimeString()}</label>
  ) : (
    <React.Fragment />
  );
  return (
    <div className={containerClasses}>
      <div className={messageClasses}>
        <label className="text">{message}</label>
        {timestampView}
      </div>
    </div>
  );
};
