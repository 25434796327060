import { Position } from '@smartaction/visuals/Types';
import { DataResponse, Methods, ServerResponse, ValidateResponseActionEnum } from 'internal/clients';
import { EntryPoint, EmptyId, ChannelType, Pointer, Container, SourceType, ChannelSourceType } from 'internal/models';
import { PostError, PostSuccess } from 'ui/events';
import { IEntryPointClient } from './IEntryPointClient';

export class EntryPointClient implements IEntryPointClient {
  serviceUrl: string;
  methods: Methods;

  constructor(serviceUrl: string, methods: Methods) {
    this.serviceUrl = `${serviceUrl}`;
    this.methods = methods;
  }

  private getUrl(snapshotId: string, moduleId: string) {
    return `${this.serviceUrl}/tenant/${this.methods
      .tenantId!}/snapshot/${snapshotId}/v1/modules/${moduleId}/entryPoints`;
  }

  async createAsync(
    snapshotId: string,
    moduleId: string,
    name: string,
    position: Position,
  ): Promise<EntryPoint | undefined> {
    const request = {
      name: name,
      position: position,
    };

    const response = await this.methods.post(this.getUrl(snapshotId, moduleId), request);

    const success = await this.methods.handleErrors('Creating Block', response);

    if (success) {
      return new EntryPoint(await this.methods.getId(response), name, '', position, [], EmptyId, [], '');
    }

    return undefined;
  }

  async updateNextIdAsync(
    snapshotId: string,
    moduleId: string,
    entryPointId: string,
    nextId: string,
  ): Promise<ServerResponse> {
    const url = this.getUrl(snapshotId, moduleId);
    const response = await this.methods.put(`${url}/${entryPointId}/next`, nextId);

    const responseValidation = await this.methods.validateResponse(
      `${ValidateResponseActionEnum.Updated} EntryPoint NextId`,
      response,
    );
    if (!responseValidation.success) {
      PostError(responseValidation.error!, true);
    }

    PostSuccess('Saved!', false);

    return responseValidation;
  }

  async updateMetadataAsync(
    snapshotId: string,
    moduleId: string,
    entryPointId: string,
    name: string,
    description: string,
  ): Promise<void> {
    const url = this.getUrl(snapshotId, moduleId);
    const response = await this.methods.put(`${url}/${entryPointId}/metadata`, {
      name: name,
      description: description,
    });

    await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Updated} EntryPoint Name`, response);
  }

  async updatePositionAsync(
    snapshotId: string,
    moduleId: string,
    entryPointId: string,
    position: Position,
  ): Promise<void> {
    const url = this.getUrl(snapshotId, moduleId);
    const response = await this.methods.put(`${url}/${entryPointId}/position`, {
      x: position.x,
      y: position.y,
    });

    await this.methods.handleErrors('Updating EntryPoint Position', response);
  }

  async deleteAsync(snapshotId: string, moduleId: string, entryPointId: string): Promise<void> {
    const url = this.getUrl(snapshotId, moduleId);
    const response = await this.methods.delete(`${url}/${entryPointId}`);

    await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Deleted} EntryPoint`, response);
  }

  async createStep(
    snapshotId: string,
    moduleId: string,
    entryPointId: string,
    type: string,
    name: string,
    index?: number,
  ): Promise<DataResponse<string>> {
    let url = `${this.getUrl(snapshotId, moduleId)}/${entryPointId}/steps`;

    if (index) {
      url += `?index=${index}`;
    }

    const request = {
      type: type,
      name: name,
    };

    const response = await this.methods.post(url, request);

    return await this.methods.validatePopToastAndReturnId(
      `${ValidateResponseActionEnum.Created} EntryPoint Step`,
      response,
    );
  }

  async addExistingStep(
    snapshotId: string,
    moduleId: string,
    entryPointId: string,
    stepId: string,
    container: Container,
    index?: number,
  ): Promise<void> {
    let url = `${this.getUrl(snapshotId, moduleId)}/${entryPointId}/steps/${stepId}`;
    url += `?index=${index}`;

    const response = await this.methods.put(url, container);

    // throws an exception if this fails.
    await this.methods.handleErrors('Updating Step Metadata', response);
  }

  async reorderSteps(snapshotId: string, moduleId: string, entryPointId: string, stepIds: string[]): Promise<void> {
    const url = `${this.getUrl(snapshotId, moduleId)}/${entryPointId}/steps`;
    const response = await this.methods.patch(url, stepIds);
    await this.methods.handleErrors('Updating Step Order in EntryPoint', response);
  }

  async createChannel(
    snapshotId: string,
    moduleId: string,
    entryPointId: string,
    name: string,
    type: ChannelType,
    source: Pointer,
    sourceType: SourceType,
  ): Promise<DataResponse<string>> {
    let url = `${this.getUrl(snapshotId, moduleId)}/${entryPointId}/channels`;
    const response = await this.methods.post(url, { name, type, source });
    return await this.methods.validatePopToastAndReturnId(
      `${ValidateResponseActionEnum.Created} EntryPoint Channel`,
      response,
    );
  }
  async updateChannelName(
    snapshotId: string,
    moduleId: string,
    entryPointId: string,
    channelId: string,
    name: string,
  ): Promise<ServerResponse> {
    let url = `${this.getUrl(snapshotId, moduleId)}/${entryPointId}/channels/${channelId}/name`;
    const response = await this.methods.plainBodyRequest(url, 'PUT', name);
    return await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Updated} EntryPoint Channel name`,
      response,
    );
  }

  async updateChannelType(
    snapshotId: string,
    moduleId: string,
    entryPointId: string,
    channelId: string,
    type: ChannelSourceType,
  ): Promise<ServerResponse> {
    let url = `${this.getUrl(snapshotId, moduleId)}/${entryPointId}/channels/${channelId}/type`;
    const response = await this.methods.put(url, type);
    return await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Updated} EntryPoint Channel type`,
      response,
    );
  }

  async updateChannelSource(
    snapshotId: string,
    moduleId: string,
    entryPointId: string,
    channelId: string,
    source: Pointer,
  ): Promise<ServerResponse> {
    let url = `${this.getUrl(snapshotId, moduleId)}/${entryPointId}/channels/${channelId}/source`;
    const response = await this.methods.put(url, source);
    return await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Updated} EntryPoint Channel source`,
      response,
    );
  }

  async createChannelSipHeader(
    snapshotId: string,
    moduleId: string,
    entryPointId: string,
    channelId: string,
    name: string,
    source: Pointer,
  ): Promise<ServerResponse> {
    let url = `${this.getUrl(snapshotId, moduleId)}/${entryPointId}/channels/${channelId}`;
    const response = await this.methods.post(url, { name, source });
    return await this.methods.validatePopToastAndReturnId(
      `${ValidateResponseActionEnum.Created} EntryPoint Channel Sip Header`,
      response,
    );
  }

  async updateChannelSipHeaderName(
    snapshotId: string,
    moduleId: string,
    entryPointId: string,
    channelId: string,
    name: string,
    headerId: string,
  ): Promise<ServerResponse> {
    let url = `${this.getUrl(snapshotId, moduleId)}/${entryPointId}/channels/${channelId}/sip-headers/${headerId}/name`;
    const response = await this.methods.plainBodyRequest(url, 'PUT', name);
    return await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Updated} EntryPoint Channel Sip Header name`,
      response,
    );
  }
  async updateChannelSipHeaderSource(
    snapshotId: string,
    moduleId: string,
    entryPointId: string,
    channelId: string,
    source: Pointer,
    headerId: string,
  ): Promise<ServerResponse> {
    let url = `${this.getUrl(
      snapshotId,
      moduleId,
    )}/${entryPointId}/channels/${channelId}/sip-headers/${headerId}/source`;
    const response = await this.methods.put(url, source);
    return await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Updated} EntryPoint Channel Sip Header source`,
      response,
    );
  }

  async deleteChannelSipHeader(
    snapshotId: string,
    moduleId: string,
    entryPointId: string,
    channelId: string,
    headerId: string,
  ): Promise<ServerResponse> {
    let url = `${this.getUrl(snapshotId, moduleId)}/${entryPointId}/channels/${channelId}/sip-headers/${headerId}`;
    const response = await this.methods.delete(url);
    return await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Deleted} EntryPoint Channel Sip Header`,
      response,
    );
  }

  async deleteChannel(
    snapshotId: string,
    moduleId: string,
    entryPointId: string,
    channelId: string,
  ): Promise<ServerResponse> {
    let url = `${this.getUrl(snapshotId, moduleId)}/${entryPointId}/channels/${channelId}`;
    const response = await this.methods.delete(url);
    return await this.methods.validateAndPopToast(`${ValidateResponseActionEnum.Deleted} EntryPoint Channel`, response);
  }

  async updateNonHumanFunctionId(
    snapshotId: string,
    moduleId: string,
    entryPointId: string,
    channelId: string,
    funcId: string,
  ): Promise<ServerResponse> {
    let url = `${this.getUrl(snapshotId, moduleId)}/${entryPointId}/channels/${channelId}/non-human`;
    const response = await this.methods.put(url, funcId);
    return await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Updated} EntryPoint Channel NonHumanFunction`,
      response,
    );
  }
  async updateIndeterminateFunctionId(
    snapshotId: string,
    moduleId: string,
    entryPointId: string,
    channelId: string,
    funcId: string,
  ): Promise<ServerResponse> {
    let url = `${this.getUrl(snapshotId, moduleId)}/${entryPointId}/channels/${channelId}/indeterminate`;
    const response = await this.methods.put(url, funcId);
    return await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Updated} EntryPoint Channel IndeterminateFunction`,
      response,
    );
  }

  async updateDialerName(
    snapshotId: string,
    moduleId: string,
    entryPointId: string,
    channelId: string,
    dialerName: Pointer,
  ): Promise<ServerResponse> {
    let url = `${this.getUrl(snapshotId, moduleId)}/${entryPointId}/channels/${channelId}/dialer-name`;
    const response = await this.methods.put(url, dialerName);
    return await this.methods.validateAndPopToast(
      `${ValidateResponseActionEnum.Updated} EntryPoint Channel's Dialer Name`,
      response,
    );
  }
}
