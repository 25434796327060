import { Block, EmptyId, EmptyPointer, Module } from 'internal/models';
import React, { useEffect, useRef, useState, useCallback } from 'react';
import {
  ReactFlow,
  NodeChange,
  NodeRemoveChange,
  Connection,
  Controls,
  Edge,
  MiniMap,
  useReactFlow,
  applyEdgeChanges,
  applyNodeChanges,
  Node,
  EdgeChange,
  NodeTypes,
  OnConnectStartParams,
  XYPosition,
  ReactFlowProvider,
} from '@xyflow/react';
import { useBots, useClearToolbar, useSetToolbar, useSelectedNodeIds, SelectedNodeIdsProvider } from 'contexts';
import './Module.scss';
import { useWindows, WindowProvider } from '@smartaction/visuals';
import { ModuleChildTypes, Note } from 'internal/models';
import { DecisionNode, GetHandleForBranch } from './module/nodes/Decision';
import { Position } from '@smartaction/visuals/Types';
import { useFlow } from 'contexts';
import { useClient } from 'contexts/ClientContext';
import { useSnapshot } from 'contexts';
import { NoteNode } from './module/nodes/Note';
import { BranchNode, BranchNodePropsType } from './module/nodes/Branch';
import ModuleToolbar from './module/Toolbar';
import { EntryPointNode } from './module/nodes/EntryPoint';
import { useDebouncer } from 'ui/hooks';
import { PostError } from 'ui/events';
import { DefaultPublisher } from '@smartaction/common';
import { GetQueryParams } from 'ui/utils';
import { BlockNode } from './module/nodes/Block';

type ModuleProps = {
  module: Module;
  isReadOnly: boolean;
};

const nodeTypes: NodeTypes = {
  [ModuleChildTypes.Block]: BlockNode,
  [ModuleChildTypes.Decision]: DecisionNode,
  [ModuleChildTypes.Branch]: BranchNode,
  [ModuleChildTypes.Note]: NoteNode,
  [ModuleChildTypes.EntryPoint]: EntryPointNode,
};

export const ModuleView: React.FC<ModuleProps> = (props) => {
  return (
    <ReactFlowProvider>
      <WindowProvider>
        <SelectedNodeIdsProvider>
          <ModuleViewContents {...props} />
        </SelectedNodeIdsProvider>
      </WindowProvider>
    </ReactFlowProvider>
  );
};

export const ModuleViewContents: React.FC<ModuleProps> = ({ module, isReadOnly }) => {
  const { flow, updateFlow } = useFlow();
  const { isReadOnlyBot, updateReadOnly } = useBots();
  const snapShotData = useSnapshot();
  const startConnectParams = useRef<OnConnectStartParams | undefined>(undefined);
  const client = useClient('flow');
  const setToolbar = useSetToolbar();
  const clearToolbar = useClearToolbar();
  const ref = useRef<HTMLDivElement>(null);
  const reactFlowRef = useRef<HTMLDivElement>(null);
  const reactFlowInstance = useReactFlow();
  const deleteCancelled = useRef(false);
  const positionDebouncer = useDebouncer(1000);
  const [nodes, setNodes] = useState<any[]>([]);
  const [edges, setEdges] = useState<any[]>([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const windows = useWindows();
  const moduleId = module.id;
  const { selectedNodeIds, setSelectedNodeIds } = useSelectedNodeIds();
  const edgeColor: string = '#00A4E8';
  const edgeWidthNormal = 1;
  const edgeWidthSelected = 5;

  const onPaneClick = () => {
    setSelectedNodeIds([]);
  };

  useEffect(() => {
    const params = GetQueryParams();

    const blockId = params.get('block');
    if (blockId) {
      return;
    }

    const entryPointId = params.get('entryPoint');
    if (entryPointId) {
      return;
    }
    isReadOnly && updateReadOnly(isReadOnly);
  }, []);

  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, [ref]);

  useEffect(() => {
    const subId = DefaultPublisher.subscribe('ModuleItemRenamedEvent', (evt) => {
      windows.renameWindow(evt.itemId, evt.newName);
    });
    return () => {
      DefaultPublisher.unsubscribe('ModuleItemRenamedEvent', subId);
    };
  }, [windows]);

  useEffect(() => {
    const blocks = module.blocks.map((b) => {
      return {
        id: b.id,
        type: ModuleChildTypes.Block,
        position: b.position,
        data: { moduleId: module.id, block: b },
      };
    });
    const decisions = module.decisions.map((d) => {
      return {
        id: d.id,
        type: ModuleChildTypes.Decision,
        position: d.position,
        data: { moduleId: module.id, decision: d },
      };
    });
    const notes = module.notes.map((b) => {
      return { id: b.id, type: ModuleChildTypes.Note, position: b.position, data: { moduleId: module.id, id: b.id } };
    });
    const entryPoints = module.entryPoints.map((d) => {
      return {
        id: d.id,
        type: ModuleChildTypes.EntryPoint,
        position: d.position,
        data: { moduleId: module.id, entryPoint: d },
      };
    });
    const branches = module.decisions.flatMap((d) =>
      d.branches.map((b) => {
        return {
          id: b.id,
          type: ModuleChildTypes.Branch,
          position: b.position,
          data: { moduleId: module.id, decision: d, branch: b },
        };
      }),
    );
    const blockEdges = module.blocks
      .filter((b) => b.nextId !== EmptyId)
      .map((b) => {
        return {
          id: `e${b.id}-${b.nextId}`,
          source: b.id,
          target: b.nextId,
          type: 'smoothstep',
          snapGrid: [25, 25],
          style: {
            stroke: edgeColor,
            strokeWidth: selectedNodeIds.includes(b.id) ? edgeWidthSelected : edgeWidthNormal,
          },
        };
      });
    const entryPointEdges = module.entryPoints
      .filter((b) => b.nextId !== EmptyId)
      .map((b) => {
        return {
          id: `e${b.id}-${b.nextId}`,
          source: b.id,
          target: b.nextId,
          type: 'smoothstep',
          snapGrid: [25, 25],
          style: {
            stroke: edgeColor,
            strokeWidth: selectedNodeIds.includes(b.id) ? edgeWidthSelected : edgeWidthNormal,
          },
        };
      });
    const decisionEdges = [
      ...module.decisions.flatMap((d) =>
        d.branches.map((b) => {
          return {
            id: `e${d.id}-${b.id}`,
            source: d.id,
            target: b.id,
            type: 'smoothstep',
            sourceHandle: GetHandleForBranch(d, b),
            style: {
              stroke: edgeColor,
              strokeWidth: selectedNodeIds.includes(d.id) ? edgeWidthSelected : edgeWidthNormal,
            },
          };
        }),
      ),
    ];
    const branchEdges = module.decisions.flatMap((d) =>
      d.branches.map((b) => {
        return {
          id: `e${b.id}-${b.nextId}`,
          source: b.id,
          target: b.nextId,
          type: 'smoothstep',
          snapGrid: [25, 25],
          style: {
            stroke: edgeColor,
            strokeWidth: selectedNodeIds.includes(b.id) ? edgeWidthSelected : edgeWidthNormal,
          },
        };
      }),
    );
    setNodes([...entryPoints, ...blocks, ...decisions, ...branches, ...notes]);
    setEdges([...blockEdges, ...entryPointEdges, ...decisionEdges, ...branchEdges]);
    setIsLoaded(true);
  }, [
    flow,
    moduleId,
    flow.modules,
    module.entryPoints,
    module.blocks,
    module.decisions,
    module.notes,
    isReadOnlyBot,
    selectedNodeIds,
  ]);

  const rebuildEdges = () => {
    const blockEdges = module.blocks
      .filter((b) => b.nextId !== EmptyId)
      .map((b) => {
        return {
          id: `e${b.id}-${b.nextId}`,
          source: b.id,
          target: b.nextId,
          type: 'smoothstep',
          snapGrid: [25, 25],
          style: {
            stroke: edgeColor,
            strokeWidth: selectedNodeIds.includes(b.id) ? edgeWidthSelected : edgeWidthNormal,
          },
        };
      });
    const entryPointEdges = module.entryPoints
      .filter((b) => b.nextId !== EmptyId)
      .map((b) => {
        return {
          id: `e${b.id}-${b.nextId}`,
          source: b.id,
          target: b.nextId,
          type: 'smoothstep',
          snapGrid: [25, 25],
          style: {
            stroke: edgeColor,
            strokeWidth: selectedNodeIds.includes(b.id) ? edgeWidthSelected : edgeWidthNormal,
          },
        };
      });
    const decisionEdges = [
      ...module.decisions.flatMap((d) =>
        d.branches.map((b) => {
          return {
            id: `e${d.id}-${b.id}`,
            source: d.id,
            target: b.id,
            type: 'smoothstep',
            sourceHandle: GetHandleForBranch(d, b),
            style: {
              stroke: edgeColor,
              strokeWidth: selectedNodeIds.includes(d.id) ? edgeWidthSelected : edgeWidthNormal,
            },
          };
        }),
      ),
    ];
    const branchEdges = module.decisions.flatMap((d) =>
      d.branches.map((b) => {
        return {
          id: `e${b.id}-${b.nextId}`,
          source: b.id,
          target: b.nextId,
          type: 'smoothstep',
          snapGrid: [25, 25],
          style: {
            stroke: edgeColor,
            strokeWidth: selectedNodeIds.includes(b.id) ? edgeWidthSelected : edgeWidthNormal,
          },
        };
      }),
    );
    setEdges([...blockEdges, ...entryPointEdges, ...decisionEdges, ...branchEdges]);
  };

  const onConnectStart = (_: any, params: OnConnectStartParams) => {
    startConnectParams.current = params;
  };

  const createNewBlock = async (position: Position | XYPosition, nextId?: string) => {
    const block = new Block(EmptyId, 'New Block', '', position, [], EmptyId, new EmptyPointer());
    const response = await client.modules.blocks.createAsync(
      snapShotData.snapshot.id,
      moduleId,
      block.name,
      block.position,
    );
    if (response.success) {
      block.id = response.data!;
      if (nextId) {
        await client.modules.blocks.updateNextIdAsync(snapShotData.snapshot.id, moduleId, block.id, nextId);
      }
      module.blocks.push(block);
      return block;
    }

    return undefined;
  };
  // swiped from an example: https://reactflow.dev/docs/examples/nodes/add-node-on-edge-drop/
  const onConnectEnd = async (event: MouseEvent | TouchEvent) => {
    const targetIsPane = (event.target as HTMLElement).classList.contains('react-flow__pane');
    const isTouch = (event as MouseEvent).clientX === undefined;
    let x = 0;
    let y = 0;
    if (!isTouch) {
      x = (event as MouseEvent).clientX;
      y = (event as MouseEvent).clientY;
    } else {
      x = (event as TouchEvent).targetTouches[0].clientX;
      y = (event as TouchEvent).targetTouches[0].clientY;
    }
    // bail if we're not targeting the pane itself (then the normal onConnect will do the work)
    // otherwise, most of these will never be false, but TypeScript is paranoid
    if (
      !targetIsPane ||
      !startConnectParams.current ||
      ref.current === null ||
      !startConnectParams.current.nodeId ||
      !startConnectParams.current.handleType
    ) {
      return;
    }

    const id = startConnectParams.current.nodeId;
    const { top, left } = ref.current!.getBoundingClientRect();
    const estimatedPosition = reactFlowInstance.screenToFlowPosition({ x: x - left - 75, y: y - top });
    const node = nodes.find((n) => n.id === id);
    startConnectParams.current = undefined;
    if (node) {
      switch (node.type as ModuleChildTypes) {
        case ModuleChildTypes.Block:
          const block = module.blocks.find((b) => b.id === id);
          if (block) {
            // If the nextId is already set, then dragging a new connection from a block should wipe the old one. We only do a create if the block doesn't have a next already set.
            if (block.nextId !== EmptyId) {
              await client.modules.blocks.updateNextIdAsync(snapShotData.snapshot.id, moduleId, block.id, EmptyId);
              block.nextId = EmptyId;
            } else {
              // when dragging a new edge from a Block, we create another Block
              const newBlock = await createNewBlock(estimatedPosition);
              if (newBlock) {
                await client.modules.blocks.updateNextIdAsync(
                  snapShotData.snapshot.id,
                  moduleId,
                  block!.id,
                  newBlock.id,
                );
                block!.nextId = newBlock.id;
              }
            }
          }
          break;
        case ModuleChildTypes.Decision:
          const decision = module.decisions.find((d) => d.id === id);
          if (decision) {
            const branch = await client.modules.decisions.createBranchAsync(
              snapShotData.snapshot.id,
              moduleId,
              decision!.id,
              'New Branch',
              reactFlowInstance.screenToFlowPosition({ x: x - left - 75, y: y - top }),
            );
            if (!branch) {
              break;
            }
            decision.branches.push(branch);
          }
          break;
        case ModuleChildTypes.Branch:
          const node = nodes.find((n) => n.id === id);
          if (node.data.branch) {
            // If the nextId is already set, then dragging a new connection from a block should wipe the old one. We only do a create if the block doesn't have a next already set.
            if (node.data.branch.nextId !== EmptyId) {
              await client.modules.decisions.updateBranchNextIdAsync(
                snapShotData.snapshot.id,
                moduleId,
                node.data.decision.id,
                node.data.branch.id,
                EmptyId,
              );
              node.data.branch.nextId = EmptyId;
            } else {
              // when dragging a new edge from a Block, we create another Block
              const newBlock = await createNewBlock(estimatedPosition);
              if (!newBlock) {
                break;
              }
              await client.modules.decisions.updateBranchNextIdAsync(
                snapShotData.snapshot.id,
                moduleId,
                node.data.decision.id,
                node.data.branch.id,
                newBlock.id,
              );
              node.data.branch.nextId = newBlock.id;
            }
          }

          break;
        case ModuleChildTypes.EntryPoint:
          const entryPoint = module.entryPoints.find((ep) => ep.id === id);
          if (entryPoint) {
            // If the nextId is already set, then dragging a new connection from a block should wipe the old one. We only do a create if the block doesn't have a next already set.
            if (entryPoint.nextId !== EmptyId) {
              await client.modules.entryPoints.updateNextIdAsync(
                snapShotData.snapshot.id,
                moduleId,
                entryPoint.id,
                EmptyId,
              );
              entryPoint.nextId = EmptyId;
            } else {
              // when dragging a new edge from an EntryPoint, we create a new Block it's now connected to
              const newBlock = await createNewBlock(estimatedPosition);
              if (!newBlock) {
                break;
              }
              await client.modules.entryPoints.updateNextIdAsync(
                snapShotData.snapshot.id,
                moduleId,
                entryPoint.id,
                newBlock.id,
              );
              entryPoint.nextId = newBlock.id;
            }
          }
          break;
        default:
          return;
      }
    }
    updateFlow(() => {});
  };

  const onNodeDragStop = (evt: React.MouseEvent, node: Node) => {
    changeNodePosition(node);
  };

  const changeNodePosition = (node: Node) => {
    const finish = (position: XYPosition, storedPosition: Position | undefined, saveAction: () => Promise<void>) => {
      // This will only be undefined if we didn't find the object, which is a weird state we can't really handle.
      if (!storedPosition) {
        throw new Error(`Module Item is missing!`);
      }

      if (Math.abs(position.x - storedPosition.x) < 1 || Math.abs(position.y - storedPosition.y) < 1) {
        return; // not changing anything for a 1 pixel move
      }
      // while we don't push to updateFlow, we do want to update the value so the next re-render of the flow correctly shows the updated position.
      storedPosition.x = position.x;
      storedPosition.y = position.y;
      positionDebouncer.start(() => {
        saveAction();
      });
    };
    // no longer calling updateFlow here - because we're only changing position, then we're just informing the server of the change, but keeping the local position.
    // this prevents a re-render, which causes items to be deselected.
    switch (node.type) {
      case ModuleChildTypes.Block:
        finish(node.position, module.blocks.find((b) => b.id === node.id)?.position, () =>
          client.modules.blocks.updatePositionAsync(snapShotData.snapshot.id, module.id, node.id, node.position),
        );
        break;
      case ModuleChildTypes.Decision: {
        finish(node.position, module.decisions.find((d) => d.id === node.id)?.position, () =>
          client.modules.decisions.updatePositionAsync(snapShotData.snapshot.id, module.id, node.id, node.position),
        );
        break;
      }
      case ModuleChildTypes.Branch: {
        const branchNode = node as BranchNodePropsType;
        const decision = module.decisions.find((d) => d.id === branchNode.data.decision.id);
        if (!decision) {
          break;
        }
        finish(node.position, decision?.branches.find((b) => b.id === node.id)?.position, async () => {
          await client.modules.decisions.updateBranchPositionAsync(
            snapShotData.snapshot.id,
            module.id,
            decision.id,
            branchNode.id,
            node.position,
          );
          rebuildEdges();
        });
        break;
      }
      case ModuleChildTypes.EntryPoint:
        finish(node.position, module.entryPoints.find((ep) => ep.id === node.id)?.position, () =>
          client.modules.entryPoints.updatePositionAsync(snapShotData.snapshot.id, module.id, node.id, node.position),
        );
        break;
      case ModuleChildTypes.Note:
        finish(node.position, module.notes.find((n) => n.id === node.id)?.position, () =>
          client.modules.notes.updatePositionAsync(snapShotData.snapshot.id, module.id, node.id, node.position),
        );
        break;
    }
  };

  const onNodesChange = async (changes: NodeChange[]) => {
    const normalChanges = changes.filter((c) => c.type !== 'remove');
    setNodes((ns) => applyNodeChanges(normalChanges, ns));
    let nodeRemovals = changes.filter((c) => c.type === 'remove');

    if (nodeRemovals.length > 1) {
      // Need to determine how we ask about a bunch of items at once
      throw new Error('Can only handle one delete at a time for now!');
    }
    if (nodeRemovals.length > 0) {
      let nodeId = (nodeRemovals[0] as NodeRemoveChange).id;

      const block = module.blocks.find((b) => b.id === nodeId)!;
      if (block.steps.length > 0) {
        //TODO: Will need to come up with better confirm than windows object
        if (window.confirm('This block contains steps, do you really want to delete?') === true) {
          client.modules.blocks.deleteAsync(snapShotData.snapshot.id, module.id, block.id).then((x) => {
            let blockIndex = module.blocks.indexOf(block);
            module.blocks.splice(blockIndex, 1);

            setNodes((ns) => applyNodeChanges(nodeRemovals, ns));
          });
        } else {
          deleteCancelled.current = true;
        }
      } else {
        client.modules.blocks.deleteAsync(snapShotData.snapshot.id, module.id, block.id).then((x) => {
          let blockIndex = module.blocks.indexOf(block);
          module.blocks.splice(blockIndex, 1);

          setNodes((ns) => applyNodeChanges(nodeRemovals, ns));
        });
      }

      return;
    }
  };
  const onEdgesChange = useCallback(
    (changes: EdgeChange[]) => {
      if (deleteCancelled) {
        deleteCancelled.current = false;
        return;
      }

      setEdges((es) => applyEdgeChanges(changes, es));
    },
    [setEdges],
  );

  const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  };

  const onDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();

    const reactFlowBounds = reactFlowRef.current!.getBoundingClientRect();
    const type = event.dataTransfer.getData('application/reactflow') as ModuleChildTypes;
    const position = reactFlowInstance!.screenToFlowPosition({
      x: event.clientX - reactFlowBounds.left,
      y: event.clientY - reactFlowBounds.top,
    });

    switch (type) {
      case ModuleChildTypes.EntryPoint:
        const entryPoint = await client.modules.entryPoints.createAsync(
          snapShotData.snapshot.id,
          module.id,
          'New EntryPoint',
          position,
        );
        if (entryPoint) {
          updateFlow(() => module.entryPoints.push(entryPoint));
        }
        break;
      case ModuleChildTypes.Block:
        const block = await createNewBlock(position);
        if (block) {
          // createNewBlock calls to push the block into the module already, so we just need to cause an update
          updateFlow(() => {});
        }
        break;
      case ModuleChildTypes.Decision:
        const decision = await client.modules.decisions.createAsync(
          snapShotData.snapshot.id,
          module.id,
          'New Decision',
          position,
        );
        if (decision) {
          updateFlow(() => module.decisions.push(decision));
        }
        break;
      case ModuleChildTypes.Note:
        const note = await client.modules.notes.createAsync(
          snapShotData.snapshot.id,
          module.id,
          'New Note',
          position,
          Note.defaultSize,
          Note.defaultSize,
          Note.defaultColor,
          Note.defaultTextColor,
        );
        if (note) {
          updateFlow(() => module.notes.push(note));
        }
        break;
    }
  };

  const onConnect = (connection: Edge<any> | Connection) => {
    // one way moves, where we don't drop on a target, are handled by onConnectStart and onConnectEnd
    if (!connection.source || !connection.target) {
      return;
    }

    const source = nodes.find((n) => n.id === connection.source);
    const target = nodes.find((n) => n.id === connection.target);

    // User tried to attach to a branch, which is something that should only be done automatically (drawing from a Decision to its Branch).
    if (target.type === ModuleChildTypes.Branch || target.type === ModuleChildTypes.EntryPoint) {
      return;
    }

    switch (source.type as ModuleChildTypes) {
      case ModuleChildTypes.Block:
        if (target.type === ModuleChildTypes.Block) {
          const targetBlock = module.blocks.find((b) => b.id === target.id);
          if (targetBlock && targetBlock.nextId === source.id) {
            const sourceName = module.blocks.find((b) => b.id === source.id)?.name || 'the requested block';
            PostError(`Circular connection! First disconnect ${targetBlock.name} from ${sourceName}.`);
            return;
          }
        }
        client.modules.blocks.updateNextIdAsync(snapShotData.snapshot.id, moduleId, source.id, target.id).then((r) => {
          if (r.success) {
            updateFlow(() => {
              const block = module.blocks.find((b) => b.id === source.id);
              block!.nextId = target.id;
            });
          }
        });
        break;
      // creating new links to branches from a Decision is handled through onConnectEnd
      case ModuleChildTypes.Decision:
        return;
      case ModuleChildTypes.Branch:
        const node = source;
        client.modules.decisions
          .updateBranchNextIdAsync(
            snapShotData.snapshot.id,
            moduleId,
            node.data.decision.id,
            node.data.branch.id,
            target.id,
          )
          .then((r) => {
            if (r.success) {
              updateFlow(() => {
                const branch = module.decisions
                  .find((d) => d.id === node.data.decision.id)
                  ?.branches.find((b) => b.id === node.data.branch.id);
                branch!.nextId = target.id;
              });
            }
          });
        break;
      case ModuleChildTypes.EntryPoint:
        client.modules.entryPoints
          .updateNextIdAsync(snapShotData.snapshot.id, moduleId, source.id, target.id)
          .then((r) => {
            if (r.success) {
              updateFlow(() => {
                const entryPoint = module.entryPoints.find((ep) => ep.id === source.id);
                entryPoint!.nextId = target.id;
              });
            }
          });
        break;
    }
  };

  return (
    <div
      className="module graph"
      ref={ref}
      tabIndex={-1}
      onFocusCapture={() => (isReadOnly ? clearToolbar() : setToolbar(<ModuleToolbar />))}
    >
      {isLoaded && (
        <>
          <ReactFlow
            nodes={nodes}
            edges={edges}
            snapToGrid={true}
            snapGrid={[3, 3]}
            nodesDraggable={!isReadOnly}
            nodesConnectable={!isReadOnly}
            onConnect={onConnect}
            onConnectStart={onConnectStart}
            onConnectEnd={onConnectEnd}
            onNodeDragStop={onNodeDragStop}
            onDrop={onDrop}
            onDragOver={onDragOver}
            nodeTypes={nodeTypes}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            fitView={true}
            fitViewOptions={{ maxZoom: 0.5, nodes: [nodes[0]] }}
            minZoom={0.1}
            onPaneClick={onPaneClick}
          >
            <div className="reactFlowWrapper" ref={reactFlowRef}>
              <MiniMap />
              <Controls showInteractive={false} />
            </div>
          </ReactFlow>
        </>
      )}
    </div>
  );
};
