import { InputFormatterPolicy } from 'internal/models';
import React, { useEffect, useState } from 'react';
import { PolicyModalProps } from '../Types';
import { useClient, usePolicies, useSnapshot, useBots } from 'contexts';
import {
  Button,
  CloseTabEvent,
  Field,
  ListViewerOpenItemEvent,
  ListViewerPort,
  PortalConfirm,
  useId,
  usePortalModal,
} from '@smartaction/visuals';
import { EditorInterface, MonacoEditor } from 'ui/components';
import { VisualCategory } from '@smartaction/styles';
import { DefaultPublisher } from '@smartaction/common';

export const InputFormatterPolicyView: React.FC<PolicyModalProps<InputFormatterPolicy>> = ({ policy, disabled }) => {
  const inputFormatterId = useId('formatscript');
  const { updatePolicies } = usePolicies();
  const client = useClient('flow');
  const confirm = usePortalModal();
  const validate = useClient('validator');
  const snapshot = useSnapshot();
  const [script, setScript] = useState(policy.script);

  // TODO: prop drill from parent
  const { isReadOnlyBot } = useBots();
  const isReadOnly = isReadOnlyBot;

  useEffect(() => {
    setScript(policy.script);
  }, [policy.script]);

  const saveScript = (value: string) => {
    client.policies.inputFormatters.setScript(snapshot.snapshot.id, policy.id, value).then((res) => {
      if (res.success) {
        updatePolicies(() => {
          policy.script = value;
        });
        setScript(value);
      }
    });
  };

  useEffect(() => {
    const subId = DefaultPublisher.subscribe('TabCloseRequestedEvent', (evt) => {
      if (evt.id === policy.id && policy.script === script) {
        DefaultPublisher.publish(new CloseTabEvent(policy.id, true));
      } else if (evt.id === policy.id && policy.script !== script) {
        confirm.openModal(
          <PortalConfirm
            header="Close Script Editor?"
            content={`You have unsaved changes for the script for ${policy.type}. Are you sure you want to close?`}
            confirmButton={{
              label: 'Close Anyway',
              type: VisualCategory.Danger,
              clicked: () => DefaultPublisher.publish(new CloseTabEvent(policy.id, true)),
            }}
            cancelButton={{
              label: 'Cancel',
              type: VisualCategory.Light,
              clicked: () => {},
            }}
          />,
        );
      }
    });

    return () => DefaultPublisher.unsubscribe('TabCloseRequestedEvent', subId);
  }, [policy, script]);

  const editor = (
    <MonacoEditor
      id={inputFormatterId}
      isReadOnly={isReadOnly}
      value={script}
      types={[EditorInterface.ConversationState]}
      onSave={saveScript}
      validateMethod={(tenant, snapshot, script) => validate.validateInputFormatterScript(tenant, snapshot, script)}
    />
  );

  const setModal = () => {
    DefaultPublisher.publish(
      new ListViewerOpenItemEvent('flowList', ListViewerPort.Left, {
        label: ' Input Formatter Policy Script',
        id: policy.id,
        renderFunc: () => editor,
        dropType: 'module',
        closeable: true,
      }),
    );
  };

  return (
    <div>
      <Field label="Script" inputId={inputFormatterId}>
        {editor}
      </Field>
      <Button className={'mt-3'} type={VisualCategory.Primary} action={setModal}>
        Open in Tab
      </Button>
      {confirm.modal}
    </div>
  );
};
